<template>
  <div class="self-test">
    <el-row class="title">
      <h2>您一共发布了{{ test.total }}份试卷</h2>
    </el-row>
    <el-row type="flex" class="add">
      <el-button type="primary" @click="add">添加试卷</el-button>
    </el-row>
    <el-row>
      <el-table
          :data="test.records"
          border

          style="width: 100%">
        <el-table-column
            label="序号"
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            prop="name"
            label="试卷名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="introduce"
            label="试卷的介绍"
            width="180">
        </el-table-column>
        <el-table-column
            prop="type"
            label="分类"
            width="180">
          <template slot-scope="scope">
            <el-tag effect="dark">{{ getTag(scope.row.type) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="score"
            width="100"
            label="试卷的分值">
        </el-table-column>

        <el-table-column
            fixed="right"
            label="操作">
          <template slot-scope="scope">
            <el-button @click="jump(scope.row.id)"  size="small">查看</el-button>
            <el-button @click="update(scope.row)" type="primary" size="small">修改</el-button>
            <el-button @click="$router.push({ path: '/paper/modify', query: {id:scope.row.id} })" type="warning" size="small">添加题目</el-button>
            <el-button @click="deleteMyTest(scope.row.id)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-row class="bottom">
        <el-col :span="10">
          <el-pagination
              background
              :current-page="test.current"
              @current-change="handleChange"
              layout="prev, pager, next"
              :total="test.pages">
          </el-pagination>
        </el-col>
      </el-row>
    </el-row>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form ref="form" :model="updateMsg" :rules="rules" label-width="100px">
        <el-form-item label="试卷标题" prop="name">
          <el-input v-model="updateMsg.name" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="试卷类别" prop="type">
          <el-select v-model="updateMsg.type" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷介绍" prop="introduce">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="updateMsg.introduce">
          </el-input>
        </el-form-item>
        <el-form-item label="试卷分值(可选)" prop="score">
          <el-input type="number" placeholder="请输入分值(可选)" v-model="updateMsg.score">
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
         <el-button type="primary" @click="updateT('form')">确定修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

//展示个人发布的试卷模块
import {getTestByUserId, updateTest, deleteTest} from "@/api/test";
import {options} from "@/config/global";

export default {
  name: "SelfTest",
  data() {
    return {
      test: {
        total: 0,
        records: [],
        current: 0,
        pages: 0
      },
      options,
      updateMsg: {
        userId: this.$store.state.user.id,
        name: "",
        score: "",
        type: "",
        introduce: ""
      },
      dialogVisible: false,
      rules: {
        name: {required: true, message: '请输入试卷名称', trigger: 'blur'},
        score: {required: true, message: '请输入试卷分数', trigger: 'blur'},
        type: {required: true, message: '请输入试卷类别', trigger: 'blur'},
        introduce: {required: true, message: '请输入试卷介绍', trigger: 'blur'},
      },

    }

  },
  mounted() {
    this.handleChange(1);
  },

  methods: {
    // 点击了修改的按钮
    update(e) {
      this.dialogVisible = true;
      this.updateMsg = e;
    },
    // 更新数据
    updateT(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写数据",
            type: "error",
            duration: 2 * 1000
          })
          return false;
        } else {
          this.dialogVisible = false;
          updateTest(this.updateMsg).then(result => {
            // 刷新页面数据
            this.handleChange(1);
            this.$message({
              type: "success",
              message: result.data.test,
              duration: 2 * 1000
            })
          }).catch(error => {
            this.$message({
              type: "error",
              message: error.message,
              duration: 2 * 1000
            })
          })
        }
      });

    },
    // 删除文章
    deleteMyTest(id) {
      deleteTest(id).then(result => {
        this.$message({
          type: "success",
          message: result.data.test,
          duration: 2 * 1000
        })
        this.handleChange(1);
      }).catch(error => {
        this.$message({
          type: "error",
          message: error.message,
          duration: 2 * 1000
        })
      })
    },
    //
    handleChange(number) {
      getTestByUserId(this.$store.state.user.id, number).then(result => {
        this.test = result.data.test;
      }).catch(error => {
        this.$message({
          message: error.message,
          type: "error",
          duration: 2 * 1000
        })
      })
    },
    add() {
      // 添加试卷
      this.$router.push("/paper/addTest")
    },
    getTag(index) {
      return this.options[index].label;
    },
    jump(id) {
      this.$router.push(
          {
            path: "/paper/showTest",
            query: {
              id,
              isShow: 1
            }
          }
      )
    }
  }
}
</script>

<style scoped>
.self-test {
  padding: 20px;
  background-color: #fff;
}

.title {
  margin-bottom: 1.25rem;
  text-align: left;
  height: 40px;
  line-height: 40px;
}
.add{
  margin: 10px auto;
}
.card div {
  height: 3.75rem;
  line-height: 3.75rem;
  text-align: left;
}

</style>
